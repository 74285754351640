const getclassNames = (existing: string, additional?: string): string => {
    const existingsClasses = (existing && existing.split(' ')) || [];
    const additionalClasses = (additional && additional.split(' ')) || [];

    return [
        ...existingsClasses,
        ...additionalClasses
    ].join(' ');
};

export default getclassNames;