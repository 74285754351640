import { ASSESSMENTS, DEMOGRAPHICS, OUTPUTS_AND_OUTCOMES, PLANS } from "../../../constants/reportTypes";
import DemographicsResults from "./DemographicsResults";
import FormResults from "./FormResults";
import PlansResults from "./PlansResults";
import ResultProps from "./ResultProps";

const mapping: { [key: string]: (props: ResultProps) => JSX.Element } = {
    [DEMOGRAPHICS]: DemographicsResults,
    [OUTPUTS_AND_OUTCOMES]: FormResults,
    [ASSESSMENTS]: FormResults,
    [PLANS]: PlansResults
};

export {
    mapping
};