import React, { ChangeEvent, ChangeEventHandler, ForwardedRef, forwardRef } from "react";
import getclassNames from "../../utils/getClassNames";
import './Select.css';

export interface SelectProps {
    name?: string;
    id: string;
    onChange?: (value: string) => void;
    value?: string;
    label?: string;
    labelClassName?: string;
    disabled?: boolean;
    selectClassName?: string;
    children: any[];
    required?: boolean;
}

const Select = forwardRef((props: SelectProps, ref?: ForwardedRef<any>) => {

    const handleOnChange = (e: ChangeEvent<HTMLSelectElement>) => {
        if (props.onChange) {
            props.onChange(e.target.value);
        }
    };

    return (<>
        {props.label && <label htmlFor={props.id} className={props.labelClassName}>{props.label}</label>}
        {props.required && <span className="margin-start-1 text-danger">*</span>}
        <select ref={ref} id={props.id} name={props.name || props.id} 
            className={getclassNames('select', props.selectClassName)} 
            value={props.value} onChange={handleOnChange} disabled={props.disabled || false}>
            {props.children};
        </select>
    </>);
});

export default Select;