import { format } from 'date-fns';
import { useState, useEffect } from 'react';
import Button from '../components/UI/Button';
import ErrorMessages from '../components/UI/ErrorMessages';
import Input from '../components/UI/Input';
import Loader from '../components/UI/Loader';
import Select from "../components/UI/Select";
import SideMenu from "../components/UI/SideMenu";
import { ACTIVE_PARTICIPANTS, ENROLLED_PARTICIPANTS } from '../constants/reportTypes';
import Program from '../models/serviceModels/Program';
import { searchPrograms } from '../services/programsService';
import { getReport, getReportData, runReport } from '../services/reportService';
import { getExportOptions, getReportsOptions } from "../utils/sideMenuHelpers";
import { ReportResult } from '../models/serviceModels/ReportResult';
import ApiErrorMessages from '../components/UI/ApiErrorMessages';
import { FAILED_ID, SUCCESS_ID } from '../constants/reportStatuses';
import { convertAndTriggerDownload } from '../utils/reportHelpers';

const defaultDateFrom = format(new Date(new Date().getFullYear(), 0, 1), 'yyyy-MM-dd');
const defaultDateTo = format(new Date(), 'yyyy-MM-dd');

const ExportsDemographics = () => {

    const [reportType, setReportType] = useState('');
    const [activeDateFrom, setActiveDateFrom] = useState(defaultDateFrom);
    const [activeDateTo, setActiveDateTo] = useState(defaultDateTo);
    const [enrolledDateFrom, setEnrolledDateFrom] = useState(defaultDateFrom);
    const [enrolledDateTo, setEnrolledDateTo] = useState(defaultDateTo);
    const [loadingPrograms, setLoadingPrograms] = useState(false);
    const [programs, setPrograms] = useState<Program[]>();
    const [selectedProgram, setSelectedProgram] = useState<string>();
    const [error, setError] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [apiError, setApiError] = useState<any>();

    useEffect(() => {
        document.title = "Reports and Exports"
    }
        , []);

    useEffect(() => {
        if (reportType === ENROLLED_PARTICIPANTS && !programs) {
            setLoadingPrograms(true);
            searchPrograms({ skip: 0, take: 1000 }).then(result => {
                setPrograms([
                    ...result.data
                ]);
                if (result.data.length === 0) {
                    setError('No programs found');
                }
            }).catch(e => {
                setPrograms([]);
                console.error(e);
                setError('Failed to load programs');
            }).finally(() => {
                setLoadingPrograms(false);
            });
        }
    }, [reportType]);

    const handleReset = () => {
        setSelectedProgram('');
        setEnrolledDateFrom(defaultDateFrom);
        setEnrolledDateTo(defaultDateTo);
        setActiveDateFrom(defaultDateFrom);
        setActiveDateTo(defaultDateTo);
    };

    const getParameters = (): { [key: string]: string | null } => {
        const fromDate = reportType === ACTIVE_PARTICIPANTS ? activeDateFrom : enrolledDateFrom;
        const toDate = reportType === ACTIVE_PARTICIPANTS ? activeDateTo : enrolledDateTo;

        return {
            from: fromDate,
            to: toDate,
            programId: reportType === ENROLLED_PARTICIPANTS && selectedProgram ? selectedProgram : null
        };
    }

    const handleSubmit = () => {
        setSubmitting(true);
        setError('');
        setApiError(undefined);

        runReport({
            type: reportType,
            parameters: getParameters()
        }).then(result => {
            pollForResult(result).finally(() => {
                setSubmitting(false);
            });
        }).catch(e => {
            console.error(e);
            setError('Failed to run report');
            setSubmitting(false);
        })
    };

    const pollForResult = (result: ReportResult): Promise<void> => {
        return new Promise((resolve, reject) => {
            const callGetReport = () => {
                getReport(result.id).then(r => {
                    if (r.statusId === FAILED_ID) {
                        setError('Report failed to run.  Please try again later');
                        reject();
                    } else if (r.statusId === SUCCESS_ID) {
                        getReportData(result.id).then(d => {
                            convertAndTriggerDownload(d);
                            resolve();
                        }).catch(e => {
                            console.error(e);
                            setApiError(e);
                            reject();
                        })
                    } else {
                        setTimeout(callGetReport, 3000);
                    }
                }).catch(e => {
                    console.error(e);
                    setApiError(e);
                    reject();
                });
            };

            callGetReport();
        });
    };

    const getButtons = () => {
        return (<div className="row margin-top-4 justify-content-between">
            <div className="column-medium-auto">
                <Button id="reset-button" className="secondary" text="Reset" onClick={handleReset} disabled={submitting} />
            </div>
            <div className="column-medium-auto">
                <Button id="submit-button" text="Submit" onClick={handleSubmit} disabled={submitting} loading={submitting} />
            </div>
        </div>)
    }

    const getCriteria = () => {
        if (reportType === ACTIVE_PARTICIPANTS) {
            return (
                <>
                    <hr />
                    <div className="row margin-top-4">
                        <div className="column-medium-3">
                            <Input id="active-date-from" label="Active From"
                                value={activeDateFrom} onChange={(e) => setActiveDateFrom(e)}
                                type="date" />
                        </div>
                        <div className="column-medium-3">
                            <Input id="active-date-to" label="Active To"
                                value={activeDateTo} onChange={(e) => setActiveDateTo(e)}
                                type="date" />
                        </div>
                    </div>
                    {getButtons()}
                </>
            )
        }

        if (reportType === ENROLLED_PARTICIPANTS) {
            if (loadingPrograms) {
                return <Loader />;
            }

            if (programs) {
                return (
                    <>
                        <hr />
                        <div className="row margin-top-4">
                            <div className="column-medium-3">
                                <Select id="select-program" disabled={programs.length === 0} label="Program" value={selectedProgram} onChange={(e) => setSelectedProgram(e)}>
                                    <option>--Select One--</option>
                                    {programs.map(p => {
                                        return <option key={p.id} value={p.id}>{p.name}</option>
                                    })}
                                </Select>
                            </div>
                        </div>
                        <div className="row margin-top-4">
                            <div className="column-medium-3">
                                <Input id="enrolled-date-from" disabled={programs.length === 0} label="Enrolled From"
                                    value={enrolledDateFrom} onChange={(e) => setEnrolledDateFrom(e)}
                                    type="date" />
                            </div>
                            <div className="column-medium-3">
                                <Input id="enrolled-date-to" disabled={programs.length === 0} label="Enrolled To"
                                    value={enrolledDateTo} onChange={(e) => setEnrolledDateTo(e)}
                                    type="date" />
                            </div>
                        </div>
                        {getButtons()}
                    </>
                )
            }
        }

        return null;
    };

    const getMainContent = () => {
        return (<>
            <div className="row">
                <div className="column-medium-4">
                    <ErrorMessages messages={[error]} />
                    <ApiErrorMessages error={apiError} />
                    <Select id="demographics-reports" label="Report" onChange={(e) => setReportType(e)} value={reportType}>
                        <option>--Select One--</option>
                        <option value={ACTIVE_PARTICIPANTS}>Active Participants</option>
                        <option value={ENROLLED_PARTICIPANTS}>Enrolled Participants</option>
                    </Select>
                </div>
            </div>
            {getCriteria()}
        </>);
    };

    const getHeaderContent = () => {
        return <h1>Create Demographics Exports</h1>
    };

    return (
        <section id="main-content" tabIndex={-1}>
            <div className="section">
                <SideMenu ariaLabel="Reports Side Menu"
                    id="reports-menu"
                    title="Reports"
                    secondaryTitle="Exports"
                    secondaryOptions={getExportOptions()}
                    options={getReportsOptions()}
                    headerContent={getHeaderContent()}
                    mainContent={getMainContent()}
                />
            </div>

        </section>
    );
};

export default ExportsDemographics;
