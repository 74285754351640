
import React, { useEffect, useRef, useState, KeyboardEvent } from 'react';
import getclassNames from '../../utils/getClassNames';
import './Modal.css';
import IconButton from './IconButton';

interface ModalProps {
    id: string;
    visible?: boolean;
    onClose?: () => void;
    children: any;
    className?: string;
    title?: string;
}

const Modal = (props: ModalProps) => {
    const [visible, setVisible] = useState(props.visible || false);
    const ref = useRef<any>();

    const handleOnClose = () => {
        setVisible(false);
        if (props.onClose) {
            props.onClose();
        }
    }

    useEffect(() => {
        setVisible(props.visible || false);
    }, [props.visible]);

    useEffect(() => {
        setTimeout(() => {
            if (visible && ref.current) {
                ref.current.focus();
            }
        }, 0);
    }, [visible]);

    const handleEscapeKeyUp = (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.code === 'Escape') {
            handleOnClose();
        }
    }

    if (!visible) return null;

    return (
        <div id={props.id} className="modal" onKeyUp={handleEscapeKeyUp}>
            <div className={getclassNames('modal-content', props.className)} ref={ref} tabIndex={0}>
                <div className="row justify-content-between align-items-center">
                    <div className="column-auto">
                        <h3>{props.title || ''}</h3>
                    </div>
                    <div className="column-auto">
                        <IconButton id="close-modal" icon={<i className="ri-close-line"></i>} className="flat small" onClick={handleOnClose}/>
                    </div>
                </div>
                <div className="row">
                    <div className="column">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>);
};

export default Modal;