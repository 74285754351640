import { format } from 'date-fns';
import { useState, useEffect } from 'react';
import Button from '../components/UI/Button';
import ErrorMessages from '../components/UI/ErrorMessages';
import Input from '../components/UI/Input';
import Loader from '../components/UI/Loader';
import Select from "../components/UI/Select";
import SideMenu from "../components/UI/SideMenu";
import { ACTIVE_PARTICIPANTS_SUMMARY, ENROLLED_PARTICIPANTS_SUMMARY } from '../constants/reportTypes';
import Program from '../models/serviceModels/Program';
import { searchPrograms } from '../services/programsService';
import { getReport, getReportData, runReport } from '../services/reportService';
import { getExportOptions, getReportsOptions } from "../utils/sideMenuHelpers";
import { ReportResult } from '../models/serviceModels/ReportResult';
import ApiErrorMessages from '../components/UI/ApiErrorMessages';
import { FAILED_ID, SUCCESS_ID } from '../constants/reportStatuses';
import { convertToReportResults } from '../utils/reportHelpers';
import { DemographicsReportResults } from '../models/serviceModels/DemographicsReportResults';
import useQuotas from '../hooks/useQuotas';

const defaultDateFrom = format(new Date(new Date().getFullYear(), 0, 1), 'yyyy-MM-dd');
const defaultDateTo = format(new Date(), 'yyyy-MM-dd');

const ReportsDemographics = () => {

    const [reportType, setReportType] = useState('');
    const [activeDateFrom, setActiveDateFrom] = useState(defaultDateFrom);
    const [activeDateTo, setActiveDateTo] = useState(defaultDateTo);
    const [enrolledDateFrom, setEnrolledDateFrom] = useState(defaultDateFrom);
    const [enrolledDateTo, setEnrolledDateTo] = useState(defaultDateTo);
    const [loadingPrograms, setLoadingPrograms] = useState(false);
    const [programs, setPrograms] = useState<Program[]>();
    const [selectedProgram, setSelectedProgram] = useState<string>();
    const [error, setError] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [apiError, setApiError] = useState<any>();
    const [reportResults, setReportResults] = useState<DemographicsReportResults>();
    const quotas = useQuotas();

    useEffect(() => {
        document.title = "Reports and Exports"
    }, []);

    useEffect(() => {
        if (reportType === ENROLLED_PARTICIPANTS_SUMMARY && !programs) {
            setLoadingPrograms(true);
            searchPrograms({ skip: 0, take: 1000 }).then(result => {
                setPrograms([
                    ...result.data
                ]);
                if (result.data.length === 0) {
                    setError('No programs found');
                }
            }).catch(e => {
                setPrograms([]);
                console.error(e);
                setError('Failed to load programs');
            }).finally(() => {
                setLoadingPrograms(false);
            });
        }

        setReportResults(undefined);
    }, [reportType]);

    const handleReset = () => {
        setSelectedProgram('');
        setReportType('');
        setEnrolledDateFrom(defaultDateFrom);
        setEnrolledDateTo(defaultDateTo);
        setActiveDateFrom(defaultDateFrom);
        setActiveDateTo(defaultDateTo);
        setReportResults(undefined);
    };

    const getParameters = (): { [key: string]: string | null } => {
        const fromDate = reportType === ACTIVE_PARTICIPANTS_SUMMARY ? activeDateFrom : enrolledDateFrom;
        const toDate = reportType === ACTIVE_PARTICIPANTS_SUMMARY ? activeDateTo : enrolledDateTo;

        return {
            from: fromDate,
            to: toDate,
            programId: reportType === ENROLLED_PARTICIPANTS_SUMMARY && selectedProgram ? selectedProgram : null
        };
    }

    const handleSubmit = () => {
        setSubmitting(true);
        setError('');
        setApiError(undefined);

        runReport({
            type: reportType,
            parameters: getParameters()
        }).then(result => {
            pollForResult(result).finally(() => {
                setSubmitting(false);
            });
        }).catch(e => {
            console.error(e);
            setError('Failed to run report');
            setSubmitting(false);
        })
    };

    const pollForResult = (result: ReportResult): Promise<void> => {
        return new Promise((resolve, reject) => {
            const callGetReport = () => {
                getReport(result.id).then(r => {
                    if (r.statusId === FAILED_ID) {
                        setError('Report failed to run.  Please try again later');
                        reject();
                    } else if (r.statusId === SUCCESS_ID) {
                        getReportData(result.id).then(d => {
                            convertToReportResults<DemographicsReportResults>(d).then(dd => {
                                setReportResults(dd);
                                resolve();
                            }).catch(e => {
                                console.error(e);
                                setApiError(e);
                            });
                            resolve();
                        }).catch(e => {
                            console.error(e);
                            setApiError(e);
                            reject();
                        }).finally(() => {
                            quotas.refresh();
                        });
                    } else {
                        setTimeout(callGetReport, 3000);
                    }
                }).catch(e => {
                    console.error(e);
                    setApiError(e);
                    reject();
                });
            };

            callGetReport();
        });
    };

    const getButtons = () => {
        return (<div className="row margin-top-4 justify-content-between">
            <div className="column-medium-auto">
                <Button id="reset-button" className="secondary" text="Reset" onClick={handleReset} disabled={submitting} />
            </div>
            <div className="column-medium-auto">
                <Button id="submit-button" text="Submit" onClick={handleSubmit} disabled={submitting || quotas.limits?.atOrBeyondReportLimit} loading={submitting} />
            </div>
        </div>)
    }

    const getCriteria = () => {
        if (reportType === ACTIVE_PARTICIPANTS_SUMMARY) {
            return (
                <>
                    <hr />
                    <div className="row margin-top-4">
                        <div className="column-medium-3">
                            <Input id="active-date-from" label="Active From"
                                value={activeDateFrom} onChange={(e) => setActiveDateFrom(e)}
                                type="date" />
                        </div>
                        <div className="column-medium-3">
                            <Input id="active-date-to" label="Active To"
                                value={activeDateTo} onChange={(e) => setActiveDateTo(e)}
                                type="date" />
                        </div>
                    </div>
                    {getButtons()}
                </>
            )
        }

        if (reportType === ENROLLED_PARTICIPANTS_SUMMARY) {
            if (loadingPrograms) {
                return <Loader />;
            }

            if (programs) {
                return (
                    <>
                        <hr />
                        <div className="row margin-top-4">
                            <div className="column-medium-3">
                                <Select id="select-program" disabled={programs.length === 0} label="Program" value={selectedProgram} onChange={(e) => setSelectedProgram(e)}>
                                    <option>--Select One--</option>
                                    {programs.map(p => {
                                        return <option key={p.id} value={p.id}>{p.name}</option>
                                    })}
                                </Select>
                            </div>
                        </div>
                        <div className="row margin-top-4">
                            <div className="column-medium-3">
                                <Input id="enrolled-date-from" disabled={programs.length === 0} label="Enrolled From"
                                    value={enrolledDateFrom} onChange={(e) => setEnrolledDateFrom(e)}
                                    type="date" />
                            </div>
                            <div className="column-medium-3">
                                <Input id="enrolled-date-to" disabled={programs.length === 0} label="Enrolled To"
                                    value={enrolledDateTo} onChange={(e) => setEnrolledDateTo(e)}
                                    type="date" />
                            </div>
                        </div>
                        {selectedProgram && getButtons()}
                    </>
                )
            }
        }

        return null;
    };

    const getReportResults = () => {
        if (!reportResults) {
            return <></>;
        }

        const reportTables = [];

        reportTables.push(<div key="total-participants" className="column-12 margin-top-5">
            <h3>{reportType === ENROLLED_PARTICIPANTS_SUMMARY ? 'Total Enrolled Participants' : 'Total Active Participants'}</h3>
            <div className="text-large">{reportResults.total}</div>
        </div>)

        if (reportResults.byAge && reportResults.byAge.length > 0) {
            reportTables.push(<div key="by-age" className="column-medium-6 margin-top-5">
                <h3>{reportType === ENROLLED_PARTICIPANTS_SUMMARY ? 'Enrolled Participants by Age' : 'Active Participants by Age'}</h3>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">age</th>
                            <th scope="col">count</th>
                            <th scope="col">percentage</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reportResults.byAge.sort((a, b) => a.age - b.age).map((a, i) => {
                            return (<tr key={i}>
                                <td>{a.age}</td>
                                <td>{a.count}</td>
                                <td>{reportResults.total !== 0 ? ((a.count / reportResults.total) * 100).toFixed(2) : 0}%</td>
                            </tr>)
                        })}
                    </tbody>
                </table>
            </div>);
        } else {
            reportTables.push(<div key="by-age" className="column-medium-6 margin-top-5">
                <h3>{reportType === ENROLLED_PARTICIPANTS_SUMMARY ? 'Enrolled Participants by Age' : 'Active Participants by Age'}</h3>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">age</th>
                            <th scope="col">count</th>
                            <th scope="col">percentage</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={2}>
                                <div className="row justify-content-center">
                                    <div className="column-auto">
                                        No results found
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>);
        }

        if (reportResults.byGender && reportResults.byGender.length > 0) {
            reportTables.push(<div key="by-gender" className="column-medium-6 margin-top-5">
                <h3>{reportType === ENROLLED_PARTICIPANTS_SUMMARY ? 'Enrolled Participants by Gender' : 'Active Participants by Gender'}</h3>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">gender</th>
                            <th scope="col">count</th>
                            <th scope="col">percentage</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reportResults.byGender.sort((a, b) => {
                            const firstValue = a.genderName.toLowerCase();
                            const secondValue = b.genderName.toLowerCase();
                            if (firstValue < secondValue) {
                                return -1;
                            }

                            if (firstValue > secondValue) {
                                return 1;
                            }

                            return 0;
                        }).map((a, i) => {
                            return (<tr key={i}>
                                <td>{a.genderName}</td>
                                <td>{a.count}</td>
                                <td>{reportResults.total !== 0 ? ((a.count / reportResults.total) * 100).toFixed(2) : 0}%</td>
                            </tr>)
                        })}
                    </tbody>
                </table>
            </div>);
        } else {
            reportTables.push(<div key="by-gender" className="column-medium-6 margin-top-5">
                <h3>{reportType === ENROLLED_PARTICIPANTS_SUMMARY ? 'Enrolled Participants by Gender' : 'Active Participants by Gender'}</h3>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">gender</th>
                            <th scope="col">count</th>
                            <th scope="col">percentage</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={2}>
                                <div className="row justify-content-center">
                                    <div className="column-auto">
                                        No results found
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>);
        }

        if (reportResults.byRace && reportResults.byRace.length > 0) {
            reportTables.push(<div key="by-race" className="column-medium-6 margin-top-5">
                <h3>{reportType === ENROLLED_PARTICIPANTS_SUMMARY ? 'Enrolled Participants by Race' : 'Active Participants by Race'}</h3>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">race</th>
                            <th scope="col">count</th>
                            <th scope="col">percentage</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reportResults.byRace.sort((a, b) => {
                            const firstValue = a.raceName.toLowerCase();
                            const secondValue = b.raceName.toLowerCase();
                            if (firstValue < secondValue) {
                                return -1;
                            }

                            if (firstValue > secondValue) {
                                return 1;
                            }

                            return 0;
                        }).map((a, i) => {
                            return (<tr key={i}>
                                <td>{a.raceName}</td>
                                <td>{a.count}</td>
                                <td>{reportResults.total !== 0 ? ((a.count / reportResults.total) * 100).toFixed(2) : 0}%</td>
                            </tr>)
                        })}
                    </tbody>
                </table>
            </div>);
        } else {
            reportTables.push(<div key="by-race" className="column-medium-6 margin-top-5">
                <h3>{reportType === ENROLLED_PARTICIPANTS_SUMMARY ? 'Enrolled Participants by Race' : 'Active Participants by Race'}</h3>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">race</th>
                            <th scope="col">count</th>
                            <th scope="col">percentage</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={2}>
                                <div className="row justify-content-center">
                                    <div className="column-auto">
                                        No results found
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>);
        }

        return (<div className="row">
            {reportTables}
        </div>);
    }

    const getMainContent = () => {
        if (quotas.loading) {
            return <Loader />
        }

        return (<>
            <div className="row">
                <div className="column-medium-4">
                    {quotas.limits?.atOrBeyondReportLimit && <ErrorMessages messages={['Your organization has reached the daily reporting limit']} />}
                    <ErrorMessages messages={[error]} />
                    <ApiErrorMessages error={apiError} />
                    <Select id="demographics-reports" label="Report" onChange={(e) => setReportType(e)} value={reportType}>
                        <option>--Select One--</option>
                        <option value={ACTIVE_PARTICIPANTS_SUMMARY}>Active Participants</option>
                        <option value={ENROLLED_PARTICIPANTS_SUMMARY}>Enrolled Participants</option>
                    </Select>
                </div>
            </div>
            {getCriteria()}
            {getReportResults()}
        </>);
    };

    const getHeaderContent = () => {
        return <h1>Run Demographics Reports</h1>
    };

    return (
        <section id="main-content" tabIndex={-1}>
            <div className="section">
                <SideMenu ariaLabel="Reports Side Menu"
                    id="reports-menu"
                    title="Reports"
                    secondaryTitle="Exports"
                    secondaryOptions={getExportOptions()}
                    options={getReportsOptions()}
                    headerContent={getHeaderContent()}
                    mainContent={getMainContent()}
                />
            </div>

        </section>
    );
};

export default ReportsDemographics;
