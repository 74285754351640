import { useEffect } from 'react';

const Help = () => {
    useEffect(() => {
        document.title = 'countbubble Support';
    }, []);

    return (
        <section id="help-section">
            <div className="section">
                <div className="row justify-content-center">
                    <div className="column-medium-7">
                        <h1>countbubble Support</h1>
                        <h2>Need help or have a question?</h2>

                        <p>Check out our Guides and Tutorials to see if we've already answered your questions.</p>
                        <p>We've covered lots of key topics, and most of the "How To" guides include links to short videos.</p>

                        <p>If you can't find your answer, please contact us at <a className='email-link' href="mailto:contact@countbubble.com?subject=I have a question">contact@countbubble.com</a></p>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="column-medium-7">
                        <h2>Guides and Tutorials</h2>

                        <h3>Getting Started - New Organizations and Admins</h3>
                        <ul>

                            <li>
                                <a className='help-file-link' href='https://files.countbubble.com/guides/New+Customer+Getting+Started+Checklist.pdf'>New Customer Getting Started Checklist (pdf)</a>
                                <p>It's hard to know how start. This checklist will help new organizations get going with confidence. </p>
                            </li>
                            <li>
                                <a className='help-file-link' href='https://files.countbubble.com/guides/Getting+Started+with+countbubble+in+About+30+Min.pdf'>Getting Started with countbubble in About 30 Minutes (pdf)</a>
                                <p>In a hurry to get started? Not a "checklist person"? This How To guide will teach you to build a form, create a program, add a participant, and submit data. This is a great starting place for your free trial. </p>
                            </li>

                        </ul>

                        <h3>Foundational Concepts</h3>
                        <p>These are ideal for admin users and anyone who wants a deep understanding of countbubble concepts</p>

                        <ul>
                            <li>
                                <a className='help-file-link' href='https://files.countbubble.com/guides/Key+countbubble+Terms.pdf'>Key countbubble Terms (pdf)</a>
                                <p>What's a program? What are user roles? What types of forms can I build in countbubble?</p>
                            </li>
                            <li>
                                <a className='help-file-link' href='https://files.countbubble.com/guides/countbubble+Site+Organization.pdf'>countbubble Site Organization (pdf)</a>
                                <p>Learn the basic elements of a countbubble site to understand how it can be used </p>
                            </li>
                            <li>
                                <a className='help-file-link' href='https://files.countbubble.com/guides/Quick+Guide+to+Form+Field+Types.pdf'>Quick Guide to Form Field Types (pdf)</a>
                                <p>This guide describes the form field types available in the Form Builder & explains when/how each type is best used. </p>
                            </li>
                        </ul>



                        <h3>
                            How To Guides for Everyone
                        </h3>

                        <ul>
                            <li>
                                <a className='help-file-link' href='https://files.countbubble.com/guides/Add+a+Participant.pdf'>Add a Participant (pdf)</a>
                                <p>Add a new participant to your countbubble site. <br></br><span className='help-file-em'>Includes link to a 3 minute video.</span> </p>
                            </li>
                            <li>
                                <a className='help-file-link' href="https://files.countbubble.com/guides/Edit+a+participant%E2%80%99s+profile+information.pdf">Edit a Participant's Profile Information (pdf)</a>
                                <p>Learn how to change a participant's phone number, address, family members, or something else on their profile.<br></br> <span className='help-file-em'>Includes link to 3 minute video.</span> </p>
                            </li>

                            <li>
                                <a className='help-file-link' href='https://files.countbubble.com/guides/Search+for+Participants.pdf'>Search for Participants (pdf)</a>
                                <p> Learn how to your participants using the participant search bar. <br></br><span className='help-file-em'>Includes link to a 3 minute video.</span> </p>
                            </li>


                            <li>
                                <a className='help-file-link' href='https://files.countbubble.com/guides/Enroll+a+Participant+in+a+Program.pdf'>Enroll a Participant in a Program (pdf)</a>
                                <p> Learn how and why to enroll a participant in a program. <br></br><span className='help-file-em'>Includes link to a 2 minute video.</span> </p>
                            </li>

                            <li>
                                <a className='help-file-link' href='https://files.countbubble.com/guides/Track+Outputs+or+Outcomes+with+a+Participant.pdf'>Track Outputs and Outcomes with a Participant (pdf)</a>
                                <p>Learn how to quickly track data using Outputs and Outcomes forms. <br></br> <span className='help-file-em'>Includes link to a 3 minute video.</span> </p>
                            </li>
                            <li>
                                <a className='help-file-link' href='https://files.countbubble.com/guides/Reports+and+Exports+in+countbubble.pdf'>Create Reports and Exports (pdf)</a>
                                <p>Learn how to generate reports and data exports from the data you've stored. <br></br> <span className='help-file-em'>Includes link to a 7 minute video.</span> </p>
                            </li>

                            <li>
                                <a className='help-file-link' href='https://files.countbubble.com/guides/Upload+and+Download+Participant+Files.pdf'>Upload and Download Participant Files (pdf)</a>
                                <p>Learn how upload and download files and documents for participants in your programs. <br></br> <span className='help-file-em'>Includes link to a 4 minute video.</span> </p>
                            </li>


                            <li>
                                <a className='help-file-link' href='https://files.countbubble.com/guides/Using+the+Notes+Feed+to+Stay+Organized.pdf'>Using the Notes Feed to Stay Organized (pdf)</a>
                                <p>Learn how to the Notes Feed helps you add, edit, and manage the Notes you create anywhere in countbubble. <br></br> <span className='help-file-em'>Includes link to a 6 minute video.</span> </p>
                            </li>


                            <li>
                                <a className='help-file-link' href='https://files.countbubble.com/guides/Using+Plans+in+countbubble.pdf'>Create, Edit, and Use Plans (pdf)</a>
                                <p>Learn how to create Plans -aka case plans or coaching plans - add goals & tasks, edit plans, and use Plan reports. <br></br> <span className='help-file-em'>Includes links to 3 videos.</span> </p>
                            </li>


                        </ul>

                        <h3>
                            How To Guides for Admins Only
                        </h3>


                        <h4>Site Management</h4>
                        <ul>

                            <li>
                                <a className='help-file-link' href='https://files.countbubble.com/guides/Create+an+Organization.pdf'>Create an Organization (pdf)</a>
                                <p> How to create your organization in countbubble. <br></br> <span className='help-file-em'>Includes link to a 1 minute video.</span> </p>
                            </li>


                            <li>
                                <a className='help-file-link' href='https://files.countbubble.com/guides/Create+a+Program.pdf'>Create a Program (pdf)</a>
                                <p> How to create programs for your organization. <br></br><span className='help-file-em'>Includes link to a 1 minute video.</span> </p>
                            </li>
                            <li>
                                <a className='help-file-link' href='https://files.countbubble.com/guides/Assign+a+Form+to+a+Program.pdf'>Assign a Form to a Program (pdf)</a>
                                <p> How and why you must assign forms to programs before you can use them for data entry. <br></br><span className='help-file-em'>Includes link to a 2 minute video.</span> </p>
                            </li>
                            <li>
                                <a className='help-file-link' href='https://files.countbubble.com/guides/Unassign+a+Form+From+a+Program.pdf'>Unassign a Form from a Program (pdf)</a>
                                <p> How and why to enroll a participant in a program. <br></br><span className='help-file-em'>Includes link to a 2 minute video.</span></p>
                            </li>
                            <li>

                                <a className='help-file-link' href='https://files.countbubble.com/guides/Create+a+User.pdf'>Create a User (pdf)</a>
                                <p>How to create a new user in your organization. <br></br> <span className='help-file-em'>Includes link to a 2 minute video.</span> </p>
                            </li>

                            <li>
                                <a className='help-file-link' href='https://files.countbubble.com/guides/Edit+An+Existing+User.pdf'>Edit an Existing User (pdf)</a>
                                <p>How to edit the details of users in your organization. <br></br><span className='help-file-em'>Includes link to a 2 minute video.</span></p>
                            </li>
                            <li>
                                <a className='help-file-link' href='https://files.countbubble.com/guides/Upgrading+from+a+Free+Trial+to+a+Paid+Plan.pdf'>Upgrade from Free Trial to Paid Plan (pdf)</a>
                                <p>How to upgrade from a free trial to a paid account. *Check our website for <a className='help-file-link' href='https://www.countbubble.com/compare-plans'>current plan rates</a>.<br></br><span className='help-file-em'>Includes link to a 2 minute video.</span></p>
                            </li>
                        </ul>

                        <h4>Building and Managing Forms and Data</h4>
                        <ul>
                            <li>
                                <a className='help-file-link' href='https://files.countbubble.com/guides/Create+an+Outputs+%26+Outcomes+Form.pdf'>Create an Outputs and Outcomes Form (pdf)</a>
                                <p>Use the Form Builder to Create an Outputs & Outcomes form. <br></br><span className='help-file-em'>Includes link to 6 minute video.</span> </p>
                            </li>
                            <li>
                                <a className='help-file-link' href='https://files.countbubble.com/guides/Form+Builder+Basics.pdf'>Form Builder Basics (pdf)</a>
                                <p>Detailed Explanation about the Form Builder with important tips about editing & updating forms. <br></br><span className='help-file-em'>Includes link to 3 minute video.</span> </p>
                            </li>

                            <li>
                                <a className='help-file-link' href="https://files.countbubble.com/guides/The+Participant+Profile+Explanation+and+How+To+Build.pdf">The Participant Profile: Explanation and How to Build (pdf)</a>
                                <p>Learn the ins and outs of the participant profile. <br></br><span className='help-file-em'>Includes link to an 8 minute video.</span></p>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </section>
    )
};

export default Help;