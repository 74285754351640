import React, { ChangeEvent, MouseEvent, ChangeEventHandler, forwardRef, ForwardedRef } from 'react';
import getclassNames from '../../utils/getClassNames';
import './Checkbox.css';

export interface CheckboxProps {
    name?: string;
    id: string;
    onChange?: (checked: boolean) => void;
    label?: string;
    labelClassName?: string;
    inputClassName?: string;
    placeholder?: string;
    disabled?: boolean;
    checked?: boolean;
    containerClassName?: string;
    required?: boolean;
}

const Checkbox = forwardRef((props: CheckboxProps, ref?: ForwardedRef<any>) => {
    const containerClasses = [];
    if (props.containerClassName) {
        containerClasses.push(props.containerClassName);
    }
    if (props.checked) {
        containerClasses.push('selected');
    }

    const handleOnSelected = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation();

        if (!props.onChange) return;
        props.onChange(!props.checked);
    }

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();

        if (!props.onChange) return;

        props.onChange(e.target.checked);
    }

    return (
        <div ref={ref} className={getclassNames('checkbox-container', containerClasses.join(' '))} onClick={handleOnSelected}>
            <input placeholder={props.placeholder}
                type="checkbox" tabIndex={0}
                id={props.id}
                name={props.name || props.id}
                checked={props.checked}
                className={getclassNames('checkbox', props.inputClassName)}
                onChange={handleOnChange}
                disabled={props.disabled || false} />
            {props.label &&
                <label htmlFor={props.id} className={props.labelClassName} onClick={e => e.stopPropagation()}>{props.label}</label>
            }
            {props.required && <span className="margin-start-1 text-danger">*</span>}
        </div>
    )
});

export default Checkbox;