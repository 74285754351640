import React, { useCallback, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';

import LoginPage from './pages/LoginPage';
import Layout from './components/Layout/Layout';
import AddParticipant from './pages/AddParticipant';
import LandingPage from './pages/LandingPage';
import Form from './pages/Form';
import AllForms from './pages/AllForms';
import ParticipantAdditionalInformation from './pages/ParticipantAdditionalInformation';
import EditParticipant from './pages/EditParticipant';

import { useAuth0 } from "@auth0/auth0-react";
import LoggedInPage from './pages/LoggedInPage';
import RegisterPage from './pages/RegisterPage';
import axios from 'axios';
import UserProfile from './pages/UserProfile';
import EditUser from './pages/EditUser';
import ManageUsers from './pages/ManageUsers';
import CreateUser from './pages/CreateUser';
import ManagePrograms from './pages/ManagePrograms';
import CreateProgram from './pages/CreateProgram';
import EditProgram from './pages/EditProgram';
import ManageProgramForms from './pages/ManageProgramForms';
import ParticipantPrograms from './pages/ParticipantPrograms';
import Tracking from './pages/Tracking';
import ReportsDemographics from './pages/ReportsDemographics';
import ReportsOutput from './pages/ReportsOutput';
import SignUp from './pages/SignUpPage';
import DeactivatedUser from './pages/DeactivatedUser';
import ManageBilling from './pages/ManageBilling';
import ChangePlan from './pages/ChangePlan';
import ConfirmPlanChange from './pages/ConfirmPlanChange';
import PlanPaymentDetails from './pages/PlanPaymentDetails';
import ManagePaymentDetails from './pages/ManagePaymentDetails';
import EditFormInstance from './pages/EditFormInstance';
import ViewFormInstance from './pages/ViewFormInstance';
import ParticipantTrackingHistory from './pages/ParticipantTrackingHistory';
import Unauthorized from './pages/errors/Unauthorized';
import Protect from './components/Layout/Protect';
import { useMe, MeProvider } from './hooks/useMe';
import Participant from './pages/Participant';
import Admin from './pages/Admin';
import ViewOrganization from './pages/ViewOrganization';
import EditOrganization from './pages/EditOrganization';
import ExportsDemographics from './pages/ExportsDemographics';
import ExportsOutputs from './pages/ExportsOutputs';
import ExportsAssessment from './pages/ExportsAssessment';
import ManageTermsOfService from './pages/ManageTermsOfService';
import TermsOfService from './pages/TermsOfService';
import ManageUsage from './pages/ManageUsage';
import Help from './pages/Help';
import AccessDisabled from './pages/errors/AccessDisabled';
import Loader from './components/UI/Loader';
import PlanCancelled from './pages/errors/PlanCancelled';
import TrialEnded from './pages/errors/TrialEnded';
import ParticipantNotes from './pages/ParticipantNotes';
import Unsubscribe from './pages/UnsubscribePage';
import ParticipantCasePlans from './pages/ParticipantCasePlans';
import ParticipantCasePlan from './pages/ParticipantCasePlan';
import { manageFormsEnabled, reportsV2Enabled } from './utils/features';
import ReportsPlans from './pages/ReportsPlans';
import ExportsCasePlans from './pages/ExportsCasePlans';
import { createContext } from 'react';
import { OrganizationProvider, useOrganization } from './hooks/useOrganization';
import Reports from './pages/Reports';
import ManageForms from './pages/ManageForms';
import FormV2 from './pages/FormV2';

const apiAudience = process.env.REACT_APP_API_AUDIENCE;
const MeContext = createContext<MeProvider>({
  loading: true,
  error: undefined,
  user: undefined,
  refresh: () => { }
});

const OrganizationContext = createContext<OrganizationProvider>({
  loading: true,
  error: undefined,
  value: undefined,
  refresh: () => { }
});

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  let isLoggedIn = isAuthenticated;

  const unauthenticatedUris = [
    '.s3.',
    '/emails/unsubscribe'
  ];

  useEffect(() => {
    const handle = axios.interceptors.request.use(async config => {
      if (config.headers && !unauthenticatedUris.some(i => config.url?.indexOf(i) !== -1)) {
        try {
          const tokenData = await getAccessTokenSilently({
            audience: apiAudience
          });

          if (tokenData) {
            config.headers.Authorization = `Bearer ${tokenData}`;
          }
        } catch (e) {
          console.error(e);
          console.warn('Failed to get access token in order to make API call');
          throw e;
        }
      }
      return config;
    }, error => error);

    return () => {
      axios.interceptors.request.eject(handle);
    };
  }, []);

  // this needs to run after the useEffect above so that the 
  // token is attached to future calls like the useMe below
  const me = useMe();
  const organization = useOrganization();

  const whiteListedMePaths = [
    '/register',
    '/login',
    '/logged-in',
    '/signup',
    '/unsubscribe',
    '/errors/unauthorized',
    '/errors/access-disabled',
    '/errors/trial-ended',
    '/errors/plan-cancelled'
  ];

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!whiteListedMePaths.includes(location.pathname)) {
      if (!isAuthenticated) {
        navigate('/login');
      } else if (!me.loading) {
        if (!me.user || !me.user.organizationId) {
          navigate('/register');
        } else if (me.user.deactivated) {
          navigate('/users/deactivated');
        }
      }
    };
  }, [me, location, isLoading, isAuthenticated]);

  const loggedInRoutes = <>
    <Route path="/register" element={<RegisterPage />} />
    <Route path="/logged-in" element={<LoggedInPage />} />
    <Route path="/" element={<Navigate to="/home" />} />
    <Route path="/user/profile" element={<UserProfile />} />
    <Route path="/users/:id/edit" element={<EditUser />} />
    <Route path="/users/deactivated" element={<DeactivatedUser />} />
    <Route path="/help" element={<Help />} />
    <Route path="/home" element={<LandingPage />} />
    <Route path="/participants/add" element={<Protect behavior="redirect" user={me.user} roles={['Admin', 'Contributor']}><AddParticipant /></Protect>} />
    <Route path="/participants/:id/edit" element={<Protect behavior="redirect" user={me.user} roles={['Admin', 'Contributor']}><EditParticipant /></Protect>} />
    <Route path="/participants/:id" element={<Participant />}>
      <Route path="programs" element={<ParticipantPrograms />} />
      <Route path="profile-demographics" element={<ParticipantAdditionalInformation />} />
      <Route path="tracking/history" element={<ParticipantTrackingHistory />} />
      <Route path="notes" element={<ParticipantNotes />} />
      <Route path="plans" element={<ParticipantCasePlans />} />
      <Route path="plans/:casePlanId" element={<ParticipantCasePlan />} />
    </Route>
    {!manageFormsEnabled && <>
      <Route path="/forms/all" element={<AllForms />} />
      <Route path="/forms/:id/versions/:versionId" element={<Form />} />
    </>}
    <Route path="/tracking/:type" element={<Tracking />} />
    {reportsV2Enabled && <Route path="/reports" element={<Reports />} />}
    {!reportsV2Enabled &&
      <><Route path="/reports/demographics" element={<ReportsDemographics />} />
        <Route path="/reports/outputs" element={<ReportsOutput />} />
        <Route path="/reports/plans" element={<ReportsPlans />} />
        <Route path="/exports/demographics" element={<ExportsDemographics />} />
        <Route path="/exports/outputs" element={<ExportsOutputs />} />
        <Route path="/exports/assessments" element={<ExportsAssessment />} />
        <Route path="/exports/plans" element={<ExportsCasePlans />} />
      </>}
    <Route path="/forms/:formId/versions/:versionId/instances/:instanceId/view" element={<ViewFormInstance />} />      
    <Route path="/forms/:formId/versions/:versionId/instances/:instanceId/edit" element={<Protect behavior="redirect" user={me.user} roles={['Admin', 'Contributor']}><EditFormInstance /></Protect>} />
    <Route path="tos" element={<TermsOfService />} />
    <Route path="/admin" element={<Protect behavior="redirect" user={me.user} roles={['Admin']}><Admin /></Protect>}>
      {manageFormsEnabled && <>
        <Route path="forms" element={<ManageForms />} />
        <Route path="forms/:id/versions/:versionId" element={<FormV2 />} />
      </>}
      <Route path="programs/:id/edit" element={<EditProgram />} />
      <Route path="programs/create" element={<CreateProgram />} />
      <Route path="programs" element={<ManagePrograms />} />
      <Route path="programs/:id/forms" element={<ManageProgramForms />} />
      <Route path="usage" element={<ManageUsage />} />
      <Route path="users" element={<ManageUsers />} />
      <Route path="users/create" element={<CreateUser />} />
      <Route path="billing" element={<ManageBilling />} />
      <Route path="tos" element={<ManageTermsOfService />} />
      <Route path="organization" element={<ViewOrganization />} />
      <Route path="organization/edit" element={<EditOrganization />} />
      <Route path="billing/manage/payment-details" element={<ManagePaymentDetails />} />
      <Route path="billing/plan/change" element={<ChangePlan />} />
      <Route path="billing/plan/payment-details" element={<PlanPaymentDetails />} />
      <Route path="billing/plan/confirm" element={<ConfirmPlanChange />} />
      {manageFormsEnabled && <Route path="" element={<Navigate to="forms" />} />}
      {!manageFormsEnabled && <Route path="" element={<Navigate to="users" />} />}
    </Route>
    <Route path="*" element={<Navigate to="/home" />} />
  </>;

  const notLoggedInRoutes = <>
    <Route path="/" element={<Navigate to="/login" />} />
    <Route path="*" element={<Navigate to="/login" />} />
  </>;

  if (isLoading) {
    return (
      <MeContext.Provider value={me}>
        <OrganizationContext.Provider value={organization}>
          <Layout>
            <Loader />
          </Layout>
        </OrganizationContext.Provider>
      </MeContext.Provider>);
  }

  if (me.user?.organizationAccessDisabled || me.user?.organizationPlanCancelled || me.user?.organizationTrialEnded) {
    return (
      <MeContext.Provider value={me}>
        <OrganizationContext.Provider value={organization}>
          <Layout>
            <Routes>
              <Route path="/errors/unauthorized" element={<Unauthorized />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/logged-in" element={<LoggedInPage />} />
              <Route path="/admin" element={<Protect behavior="redirect" user={me.user} roles={['Admin']}><Admin /></Protect>}>
                <Route path="billing" element={<ManageBilling />} />
                <Route path="tos" element={<ManageTermsOfService />} />
                <Route path="organization" element={<ViewOrganization />} />
                <Route path="organization/edit" element={<EditOrganization />} />
                <Route path="usage" element={<ManageUsage />} />
                <Route path="users" element={<ManageUsers />} />
                <Route path="users/create" element={<CreateUser />} />
                <Route path="billing/manage/payment-details" element={<ManagePaymentDetails />} />
                <Route path="billing/plan/change" element={<ChangePlan />} />
                <Route path="billing/plan/payment-details" element={<PlanPaymentDetails />} />
                <Route path="billing/plan/confirm" element={<ConfirmPlanChange />} />
                <Route path="" element={<Navigate to="organization" />} />
              </Route>
              {me.user?.organizationAccessDisabled && <>
                <Route path="/errors/access-disabled" element={<AccessDisabled />} />
                <Route path="/" element={<Navigate to="/errors/access-disabled" />} />
                <Route path="*" element={<Navigate to="/errors/access-disabled" />} />
              </>}
              {me.user?.organizationPlanCancelled && <>
                <Route path="/errors/plan-cancelled" element={<PlanCancelled />} />
                <Route path="/" element={<Navigate to="/errors/plan-cancelled" />} />
                <Route path="*" element={<Navigate to="/errors/plan-cancelled" />} />
              </>}
              {me.user?.organizationTrialEnded && <>
                <Route path="/errors/trial-ended" element={<TrialEnded />} />
                <Route path="/" element={<Navigate to="/errors/trial-ended" />} />
                <Route path="*" element={<Navigate to="/errors/trial-ended" />} />
              </>}
            </Routes>
          </Layout>
        </OrganizationContext.Provider>
      </MeContext.Provider>);
  }

  return (
    <MeContext.Provider value={me}>
      <OrganizationContext.Provider value={organization}>
        <Layout>
          <Routes>
            <Route path="/errors/unauthorized" element={<Unauthorized />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/unsubscribe" element={<Unsubscribe />} />
            {isLoggedIn && loggedInRoutes}
            {!isLoggedIn && notLoggedInRoutes}
          </Routes>
        </Layout>
      </OrganizationContext.Provider>
    </MeContext.Provider>
  );
}

export {
  App,
  MeContext,
  OrganizationContext
};
