import { EVERYTHING, IN_PROGRESS_GOALS } from "../constants/planScopes"
import { ORGANIZATION, PROGRAMS } from "../constants/reportPerspective"
import { ACTIVE_DATE_RANGE, ACTIVITY_DATE_RANGE, ASSESSMENT_FORM_SELECTION, DONE, ENROLL_DATE_RANGE, OUTPUTS_AND_OUTCOMES_FORM_SELECTION, PLAN_SCOPE, PROGRAM_NAME, REPORT_PERSPECTIVE } from "../constants/reportStep"
import { ASSESSMENTS, DEMOGRAPHICS, OUTPUTS_AND_OUTCOMES, PLANS } from "../constants/reportTypes"

const mapping: { [key: string]: { step: string, routeByValues: boolean }} = {
    [PLANS]: { step: PLAN_SCOPE, routeByValues: true },
    [`${PLANS}/${PLAN_SCOPE}[${IN_PROGRESS_GOALS}]`]: { step: DONE, routeByValues: true },
    [`${PLANS}/${PLAN_SCOPE}[${EVERYTHING}]`]: { step: ACTIVE_DATE_RANGE, routeByValues: false },
    [`${PLANS}/${PLAN_SCOPE}[${EVERYTHING}]/${ACTIVE_DATE_RANGE}`]: { step: DONE, routeByValues: false },
    [DEMOGRAPHICS]: { step: REPORT_PERSPECTIVE, routeByValues: true },
    [`${DEMOGRAPHICS}/${REPORT_PERSPECTIVE}[${ORGANIZATION}]`]: { step: ACTIVE_DATE_RANGE, routeByValues: false },
    [`${DEMOGRAPHICS}/${REPORT_PERSPECTIVE}[${ORGANIZATION}]/${ACTIVE_DATE_RANGE}`]: { step: DONE, routeByValues: false },
    [`${DEMOGRAPHICS}/${REPORT_PERSPECTIVE}[${PROGRAMS}]`]: { step: PROGRAM_NAME, routeByValues: false },
    [`${DEMOGRAPHICS}/${REPORT_PERSPECTIVE}[${PROGRAMS}]/${PROGRAM_NAME}`]: { step: ENROLL_DATE_RANGE, routeByValues: false },
    [`${DEMOGRAPHICS}/${REPORT_PERSPECTIVE}[${PROGRAMS}]/${PROGRAM_NAME}/${ENROLL_DATE_RANGE}`]: { step: DONE, routeByValues: false },
    [OUTPUTS_AND_OUTCOMES]: { step: OUTPUTS_AND_OUTCOMES_FORM_SELECTION, routeByValues: false },
    [`${OUTPUTS_AND_OUTCOMES}/${OUTPUTS_AND_OUTCOMES_FORM_SELECTION}`]: { step: ACTIVITY_DATE_RANGE, routeByValues: false },
    [`${OUTPUTS_AND_OUTCOMES}/${OUTPUTS_AND_OUTCOMES_FORM_SELECTION}/${ACTIVITY_DATE_RANGE}`]: { step: DONE, routeByValues: false },
    [ASSESSMENTS]: { step: ASSESSMENT_FORM_SELECTION, routeByValues: false },
    [`${ASSESSMENTS}/${ASSESSMENT_FORM_SELECTION}`]: { step: ACTIVITY_DATE_RANGE, routeByValues: false },
    [`${ASSESSMENTS}/${ASSESSMENT_FORM_SELECTION}/${ACTIVITY_DATE_RANGE}`]: { step: DONE, routeByValues: false }
}

const getNextStep = (locator: string): { step: string, routeByValues: boolean } => {
    return mapping[locator];
} 

export {
    getNextStep
};