import { DEMOGRAPHICS, OUTPUTS_AND_OUTCOMES } from "./reportTypes";

const ORGANIZATION = 'ORGANIZATION';
const PROGRAMS = 'PROGRAMS';
const FORMS = 'FORMS';

const DEMOGRAPHICS_PERSPECTIVES = [
    //FORMS,
    ORGANIZATION,
    PROGRAMS
];

const SUBMITTED_FORMS_PERSPECTIVES = [
    ORGANIZATION,
    PROGRAMS
];

const PERSPECTIVE_NAME_MAP: {[key: string]: string} = {
    [FORMS]: 'Submitted Forms',
    [ORGANIZATION]: 'Entire Organization',
    [PROGRAMS]: 'Program Enrollment'
};

const reportTypePerspectivesMap: { [key: string]: string[] } = {
    [DEMOGRAPHICS]: DEMOGRAPHICS_PERSPECTIVES,
    [OUTPUTS_AND_OUTCOMES]: SUBMITTED_FORMS_PERSPECTIVES
};

export {
    ORGANIZATION,
    PROGRAMS,
    FORMS,
    DEMOGRAPHICS_PERSPECTIVES,
    SUBMITTED_FORMS_PERSPECTIVES,
    PERSPECTIVE_NAME_MAP,
    reportTypePerspectivesMap
};