import React, {ForwardedRef, forwardRef, MouseEvent} from "react";
import { NavLink } from "react-router-dom";
import getclassNames from "../../utils/getClassNames";
import './Button.css';

interface IconButtonProps {
    className?: string;
    type?: 'button' | 'submit' | 'reset' | undefined;
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    id: string;
    name?: string;
    loading?: boolean;
    disabled?: boolean;
    icon: any;
    href?: string;
}

const IconButton = forwardRef((props: IconButtonProps, ref?: ForwardedRef<any>) => {
    const handleOnClick = (e: MouseEvent<HTMLButtonElement>) => {
        if (props.onClick) {
            props.onClick(e);
        }
    };
    
    if (props.href) {
        return (
        <NavLink id={props.id} ref={ref} className={getclassNames('button', props.className)} to={props.href}>
            <div className="button-contents">
                {props.icon}
            </div>
        </NavLink>);
    }

    return (
        <button ref={ref} id={props.id} name={props.name || props.id} className={getclassNames('button', props.className)} onClick={handleOnClick} type={props.type || 'button'} disabled={props.disabled || false}>
            <div className="button-contents">
                {(props.loading && <div className="ri-loader-4-line spin"></div>) || null}
                {!props.loading && props.icon}
            </div>
        </button>
    );
});

export default IconButton;