import { format } from "date-fns";
import React, { Fragment, useState } from "react"
import { parseJSONDateWithoutTime } from "../../utils/dateHelpers";
import getclassNames from "../../utils/getClassNames";
import Button from "../UI/Button";
import IconButton from "../UI/IconButton";
import Loader from "../UI/Loader";
import "./SearchResultsTable.css";
import { getClientFormInstance } from "../../services/formsService";
import FormViewer from "../UI/FormViewer";
import Client from "../../models/serviceModels/Client";
import HouseholdMember from "../../models/serviceModels/HouseholdMember";
import { getClientById } from "../../services/clientsService";

interface SearchResultsTableProps {
    clients: Client[];
}

const SearchResultsTable = (props: SearchResultsTableProps) => {
    const [expandedClients, setExpandedClients] = useState<Client[]>([]);
    const [loadingClientForms, setLoadingClientForms] = useState<any[]>([]);
    const [loadingClients, setLoadingClients] = useState<string[]>([]);
    const [clientForms, setClientForms] = useState<any[]>([]);
    const [loadedClients, setLoadedClients] = useState<Client[]>([]);

    const handleOnExpand = (client: Client) => {
        setExpandedClients([
            ...expandedClients,
            client
        ]);

        const loadedClient = loadedClients.find(c => c.id === client.id);
        if (!loadedClient) {
            handleLoadingClient(client);
        }

        const existingForm = clientForms.find(cf => cf.clientId === client.id);
        if (!existingForm) {
            handleLoadingClientForm(client);
        }
    };

    const handleOnCollapse = (client: Client) => {
        setExpandedClients([
            ...expandedClients.filter(c => c !== client)
        ])
    }

    const handleLoadingClient = (client: Client) => {
        setLoadingClients([
            ...loadingClients,
            client.id
        ]);

        getClientById(client.id).then(c => {
            setLoadedClients([
                ...loadedClients,
                c
            ])
        }).catch(e => {
            console.error(e);
        }).finally(() => {
            setLoadingClients([
                ...loadingClients.filter(lc => lc !== client.id)
            ]);
        })
    };

    const handleLoadingClientForm = (client: Client) => {
        setLoadingClientForms([
            ...loadingClientForms,
            client
        ]);

        getClientFormInstance(client.id).then(formInstance => {
            if (formInstance) {
                setClientForms([
                    ...clientForms,
                    {
                        clientId: client.id,
                        ...formInstance
                    }
                ]);
            }
        }).catch(e => {
            console.error(e);
        }).finally(() => {
            setLoadingClientForms([
                ...loadingClientForms.filter(lcf => lcf !== client)
            ]);
        });
    }

    const getClientForm = (clientId: string) => {
        return clientForms.find(cf => cf.clientId === clientId);
    };

    const getLoadedClient = (clientId: string) => {
        return loadedClients.find(lc => lc.id === clientId);
    };

    return (
        <div className='search-results-container'>
            <hr></hr>
            {props.clients &&
                <div className="row justify-content-center">
                    <div className="column-medium-8">
                        <caption>
                            <h2 className="text-center">Participant Search Results</h2>

                        </caption>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th scope="col">last name</th>
                                    <th scope="col">first name</th>
                                    <th scope="col">birthdate</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.clients.map((c: Client, i: number) => (
                                    <Fragment key={`client-result-${c.id}`}>
                                        <tr className={getclassNames('table-row', (i % 2 === 0 && 'even-row') || undefined)}>
                                            <td>
                                                {!expandedClients.includes(c) && <IconButton className="small flat" id={`expand-line=${i}`} icon={<i className="ri-arrow-down-s-line"></i>} onClick={() => handleOnExpand(c)} />}
                                                {expandedClients.includes(c) && <IconButton className="small flat" id={`expand-line=${i}`} icon={<i className="ri-arrow-up-s-line"></i>} onClick={() => handleOnCollapse(c)} />}
                                            </td>
                                            <td>{c.lastName}</td>
                                            <td>{c.firstName}</td>
                                            <td>{c.dateOfBirth && format(parseJSONDateWithoutTime(c.dateOfBirth), 'MM/dd/yyyy')}</td>
                                            <td>
                                                <Button className="small margin-start-2" href={`/participants/${c.id}/profile-demographics`} id={`view-client-${i}`} text="Profile" icon={<i className="ri-arrow-right-s-line" />} iconLocation='end' />
                                            </td>
                                        </tr>
                                        {expandedClients.includes(c) && (
                                            <tr className="table-details-row">
                                                <td colSpan={7}>
                                                    <div className="row padding-3">
                                                        <div className="column">
                                                            {loadingClients.includes(c.id) && <Loader />}
                                                            {!loadingClients.includes(c.id) && getLoadedClient(c.id) && <>
                                                                <div className="row margin-top-2">
                                                                    <div className="column-medium-3">
                                                                        <label>Name</label>
                                                                        <div>{getLoadedClient(c.id)!.firstName} {getLoadedClient(c.id)!.middleName} {getLoadedClient(c.id)!.lastName}</div>
                                                                    </div>
                                                                    <div className="column-medium-3">
                                                                        <label>Date of Birth</label>
                                                                        <div>{getLoadedClient(c.id)!.dateOfBirth && format(parseJSONDateWithoutTime(getLoadedClient(c.id)!.dateOfBirth), 'MM/dd/yyyy')}</div>
                                                                    </div>
                                                                    <div className="column-medium-3">
                                                                        <label>Gender</label>
                                                                        <div>{getLoadedClient(c.id)!.genderName}</div>
                                                                    </div>
                                                                    <div className="column-medium-3">
                                                                        <label>Ethnicity</label>
                                                                        <div>{getLoadedClient(c.id)!.ethnicityName}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="row margin-top-2">
                                                                    <div className="column">
                                                                        <label>Race(s)</label>
                                                                        <div>{getLoadedClient(c.id)!.races.map(r => r.name).join(', ')}</div>
                                                                    </div>
                                                                </div>

                                                                {loadingClientForms.includes(c) && <div className="row justify-content-center margin-top-2">
                                                                    <div className="column-auto">
                                                                        <Loader />
                                                                    </div>
                                                                </div>}
                                                                {!loadingClientForms.includes(c) && clientForms.some(cf => cf.clientId === c.id) && (
                                                                    <FormViewer instance={getClientForm(c.id)} />
                                                                )}
                                                                {getLoadedClient(c.id)!.householdMembers && getLoadedClient(c.id)!.householdMembers.length > 0 && <h3>Household Members</h3>}
                                                                {getLoadedClient(c.id)!.householdMembers && getLoadedClient(c.id)!.householdMembers.length > 0 && getLoadedClient(c.id)!.householdMembers.map((hhm: HouseholdMember) => {
                                                                    return (<Fragment key={`client-household-member-${hhm.id}`}>
                                                                        <hr />
                                                                        <div className="row">
                                                                            <div className="column">
                                                                                <div className="row">
                                                                                    <div className="column-medium-3">
                                                                                        <label>Name</label>
                                                                                        <div>{hhm.firstName} {hhm.middleName} {hhm.lastName}</div>
                                                                                    </div>
                                                                                    <div className="column-medium-3">
                                                                                        <label>Date of Birth</label>
                                                                                        <div>{format(parseJSONDateWithoutTime(hhm.dateOfBirth), 'MM/dd/yyyy')}</div>
                                                                                    </div>
                                                                                    <div className="column-medium-3">
                                                                                        <label>Gender</label>
                                                                                        <div>{hhm.genderName}</div>
                                                                                    </div>
                                                                                    <div className="column-medium-3">
                                                                                        <label>Ethnicity</label>
                                                                                        <div>{hhm.ethnicityName}</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="column">
                                                                                        <label>Race(s)</label>
                                                                                        <div>{hhm.races.map(r => r.name).join(', ')}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Fragment>)
                                                                })}
                                                            </>}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            }
            {
                props.clients && props.clients.length === 0 && <div className="row justify-content-center">
                    <div className="column-auto">
                        <p>No Matches For Your Search</p>
                        <div className="row justify-content-center">
                            <div className="column-auto">
                                <Button id="create-participant" href="/participants/add" text="Add Participant" />
                            </div>
                        </div>

                    </div>
                </div>
            }
        </div >
    )
}
export default SearchResultsTable;