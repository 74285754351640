import React, { ForwardedRef, forwardRef } from "react";
import { NavLink } from "react-router-dom";
import getclassNames from "../../utils/getClassNames";
import './Button.css';

interface ButtonProps {
    text: string;
    className?: string;
    contentsClassName?: string;
    type?: 'button' | 'submit' | 'reset' | undefined;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    id: string;
    name?: string;
    loading?: boolean;
    disabled?: boolean;
    icon?: any;
    iconLocation?: 'start' | 'end';
    href?: string;
    toolTip?: string;
    role?: string;
}

const Button = forwardRef((props: ButtonProps, ref?: ForwardedRef<any>) => {
    const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
        if (props.onClick) {
            props.onClick(e);
        }
    };

    if (props.href && !props.disabled) {
        return (<NavLink id={props.id} ref={ref} className={getclassNames('button', props.className)} to={props.href} onClick={handleOnClick} role={props.role ? props.role : ""}>
            <div className={getclassNames('button-contents', props.contentsClassName)}>
                {props.iconLocation === 'start' && props.icon}
                <span className="button-text">{props.text}</span>
                {props.iconLocation === 'end' && props.icon}
            </div>
        </NavLink>);
    }

    return (
        <button id={props.id} tabIndex={0} name={props.name || props.id} className={getclassNames('button', props.className)} ref={ref} onClick={handleOnClick} type={props.type || 'button'} disabled={props.disabled || false} >
            <div className={getclassNames('button-contents', props.contentsClassName)}>
                {props.iconLocation === 'start' && props.icon}
                <span className="button-text">{props.text}</span>
                {(props.loading && <div className="ri-loader-4-line spin"></div>) || null}
                {props.iconLocation === 'end' && props.icon}
            </div>
        </button>
    );
});

export default Button;