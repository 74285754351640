const REPORT_PERSPECTIVE = 'REPORT_PERSPECTIVE';
const ACTIVE_DATE_RANGE = 'ACTIVE_DATE_RANGE';
const ACTIVITY_DATE_RANGE = 'ACTIVITY_DATE_RANGE';
const ENROLL_DATE_RANGE = 'ENROLL_DATE_RANGE';
const OUTPUTS_AND_OUTCOMES_FORM_SELECTION = 'OUTPUTS_AND_OUTCOMES_FORM_SELECTION';
const ASSESSMENT_FORM_SELECTION = 'ASSESSMENT_FORM_SELECTION';
const PROGRAM_NAME = 'PROGRAM_NAME';
const FORM_NAME = 'FORM_NAME';
const PLAN_SCOPE = 'PLAN_SCOPE';
const DONE = 'DONE';

export {
    REPORT_PERSPECTIVE,
    PROGRAM_NAME,
    FORM_NAME,
    ACTIVE_DATE_RANGE,
    ACTIVITY_DATE_RANGE,
    ENROLL_DATE_RANGE,
    OUTPUTS_AND_OUTCOMES_FORM_SELECTION,
    ASSESSMENT_FORM_SELECTION,
    PLAN_SCOPE,
    DONE
}