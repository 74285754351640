import React, { ChangeEvent, ChangeEventHandler, ForwardedRef, forwardRef, MouseEvent } from 'react';
import getclassNames from '../../utils/getClassNames';
import './RadioButton.css';

export interface RadioButtonProps {
    name?: string;
    id: string;
    onChange?: (value: string) => void;
    options: { value: string, label: string }[];
    value?: string;
    label?: string;
    labelClassName?: string;
    inputClassName?: string;
    placeholder?: string;
    disabled?: boolean;
    required?: boolean;
}

const RadioButton = forwardRef((props: RadioButtonProps, ref?: ForwardedRef<any>) => {

    const handleOnSelected = (e: MouseEvent<HTMLElement>, value: string) => {
        e.stopPropagation();

        if (!props.onChange) return;
        props.onChange(value);
    };

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>, value: string) => {
        e.stopPropagation();

        if (!props.onChange) return;
        props.onChange(value);
    };

    return (
        <div>
            {props.label && <label htmlFor={props.id} className={props.labelClassName}>{props.label}</label>} 
            {props.required && <span className="margin-start-1 text-danger">*</span>}
            {props.options && props.options.map((o, i) => {
                const containerClasses = [];

                if (i === 0) {
                    containerClasses.push('first');
                }
                if (i === props.options.length - 1) {
                    containerClasses.push('last');
                }

                if (containerClasses.length === 0) {
                    containerClasses.push('middle');
                }

                if (props.value === o.value) {
                    containerClasses.push('selected');
                }

                const id = `${props.id}-${i}-option`;
                return (<div className={getclassNames('radiobutton-container', containerClasses.join(' '))} key={id} onClick={(e) => handleOnSelected(e, o.value)}>
                    <input className={getclassNames('radiobutton', props.inputClassName)} ref={i === 0 ? ref : undefined}
                        id={id} name={id} type="radio"
                        value={o.value}
                        checked={props.value === o.value}
                        onChange={(e) => handleOnChange(e, o.value)} />
                    <label className="text-normal" htmlFor={id} onClick={e => e.stopPropagation()}>{o.label}</label>
                </div>)
            })}
        </div>
    )
});

export default RadioButton;