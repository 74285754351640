import { ForwardedRef, forwardRef } from "react";
import ErrorMessages, { ErrorMessagesProps } from "./ErrorMessages";
import Input, { InputProps } from "./Input";

interface ValidatedInputProps extends InputProps, ErrorMessagesProps {
}

const ValidatedInput = forwardRef((props: ValidatedInputProps, ref?: ForwardedRef<any>) => {
    return (<>
        <Input {...props} ref={ref} />
        <ErrorMessages {...props} />
    </>
    );
});

export default ValidatedInput;
