import { format } from 'date-fns';
import { useState, useEffect } from 'react';
import Button from '../components/UI/Button';
import ErrorMessages from '../components/UI/ErrorMessages';
import Input from '../components/UI/Input';
import Loader from '../components/UI/Loader';
import Select from "../components/UI/Select";
import SideMenu from "../components/UI/SideMenu";
import WarningMessages from '../components/UI/WarnginMessages';
import { DELIVERY_ID } from '../constants/formTypes';
import { CASE_PLANS, DELIVERY_FORMS } from '../constants/reportTypes';
import Form from '../models/serviceModels/Form';
import { getForms } from '../services/formsService';
import { getReport, getReportData, runReport } from '../services/reportService';
import { getExportOptions, getReportsOptions } from "../utils/sideMenuHelpers";
import { ReportResult } from '../models/serviceModels/ReportResult';
import { convertAndTriggerDownload } from '../utils/reportHelpers';
import { FAILED_ID, SUCCESS_ID } from '../constants/reportStatuses';
import ApiErrorMessages from '../components/UI/ApiErrorMessages';

const defaultDateFrom = format(new Date(new Date().getFullYear(), 0, 1), 'yyyy-MM-dd');
const defaultDateTo = format(new Date(), 'yyyy-MM-dd');

const ExportsCasePlans = () => {
    const [startedDateFrom, setStartedDateFrom] = useState(defaultDateFrom);
    const [startedDateTo, setStartedDateTo] = useState(defaultDateTo);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState('');
    const [apiError, setApiError] = useState<any>();

    useEffect(() => {
        document.title = "Reports and Exports - Plans"
    }, []);

    const handleReset = () => {
        setStartedDateFrom(defaultDateFrom);
        setStartedDateTo(defaultDateTo);
    };

    const getParameters = (): { [key: string]: string | null } => {
        return {
            from: startedDateFrom,
            to: startedDateTo
        };
    }


    const handleSubmit = () => {
        setSubmitting(true);
        setError('');
        setApiError(undefined);

        runReport({
            type: CASE_PLANS,
            parameters: getParameters()
        }).then(result => {
            pollForResult(result).finally(() => {
                setSubmitting(false);
            });
        }).catch(e => {
            console.error(e);
            setApiError(e);
            setSubmitting(false);
        })
    };

    const pollForResult = (result: ReportResult): Promise<void> => {
        return new Promise((resolve, reject) => {
            const callGetReport = () => {
                getReport(result.id).then(r => {
                    if (r.statusId === FAILED_ID) {
                        setError('Report failed to run.  Please try again later');
                        reject();
                    } else if (r.statusId === SUCCESS_ID) {
                        getReportData(result.id).then(d => {
                            convertAndTriggerDownload(d);
                            resolve();
                        }).catch(e => {
                            console.error(e);
                            setApiError(e);
                            reject();
                        })
                    } else {
                        setTimeout(callGetReport, 3000);
                    }
                }).catch(e => {
                    console.error(e);
                    setApiError(e);
                    reject();
                });
            };

            callGetReport();
        });
    };

    const getButtons = () => {
        return (<div className="row margin-top-4 justify-content-between">
            <div className="column-medium-auto">
                <Button id="reset-button" className="secondary" text="Reset" onClick={handleReset} disabled={submitting} />
            </div>
            <div className="column-medium-auto">
                <Button id="submit-button" text="Submit" onClick={handleSubmit} disabled={submitting} loading={submitting} />
            </div>
        </div>)
    }

    const getCriteria = () => {
        return (
            <>
                <ErrorMessages messages={[error]} />
                <ApiErrorMessages error={apiError} />
                <div className="row margin-top-4">
                    <div className="column-medium-3">
                        <Input id="active-date-from" label="Active Date From"
                            value={startedDateFrom} onChange={(e) => setStartedDateFrom(e)}
                            type="date" />
                    </div>
                    <div className="column-medium-3">
                        <Input id="active-date-to" label="Active Date To"
                            value={startedDateTo} onChange={(e) => setStartedDateTo(e)}
                            type="date" />
                    </div>
                </div>
                {getButtons()}
            </>
        );
    };

const getMainContent = () => {
    return (<>
        {getCriteria()}
    </>);
};

const getHeaderContent = () => {
    return <h1>Create Plans Exports</h1>
};

return (
    <section id="main-content" tabIndex={-1}>
        <div className="section">
            <SideMenu ariaLabel="Reports Side Menu"
                id="reports-menu"
                title="Reports"
                secondaryTitle="Exports"
                secondaryOptions={getExportOptions()}
                options={getReportsOptions()}
                headerContent={getHeaderContent()}
                mainContent={getMainContent()}
            />
        </div>

    </section>
);
};

export default ExportsCasePlans;
