import { format } from 'date-fns';
import { useState, useEffect, ChangeEvent, useContext } from 'react';
import { useParams } from "react-router-dom";
import Protect from '../components/Layout/Protect';
import Button from '../components/UI/Button';
import ErrorMessages from '../components/UI/ErrorMessages';
import Input from '../components/UI/Input';
import Loader from '../components/UI/Loader';
import Modal from '../components/UI/Modal';
import Program from '../models/serviceModels/Program';
import { enrollInProgram, getAvailablePrograms, getEnrolledPrograms, unenrollInProgram } from '../services/programsService';
import { parseJSONDateWithoutTime } from '../utils/dateHelpers';
import { MeContext } from '../App';

const ParticipantPrograms = () => {
    const { id = '' } = useParams();
    const [loadingPrograms, setLoadingPrograms] = useState(false);
    const [availablePrograms, setAvailablePrograms] = useState<Program[]>([]);
    const [enrolledPrograms, setEntrolledPrograms] = useState<Program[]>([]);
    const [failedToLoad, setFailedToLoad] = useState(false);
    const [enrolling, setEnrolling] = useState(false);
    const [unenrolling, setUnenrolling] = useState(false);
    const [error, setError] = useState('');
    const [unenrollProgram, setUnenrollProgram] = useState<Program>();
    const [unenrollReason, setUnenrollReason] = useState('');
    const [enrollProgram, setEnrollProgram] = useState<Program>();
    const [enrollDate, setEnrollDate] = useState(format(new Date(), 'yyyy-MM-dd'));
    const me = useContext(MeContext);

    useEffect(() => {
        setLoadingPrograms(true);
        setFailedToLoad(false);

        Promise.all([getAvailablePrograms(id), getEnrolledPrograms(id)]).then(([availableProgramsResult, enrolledProgramsResult]) => {
            availableProgramsResult.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
            setAvailablePrograms(availableProgramsResult);

            enrolledProgramsResult.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
            setEntrolledPrograms(enrolledProgramsResult);
        }).catch(e => {
            console.error(e);
            setFailedToLoad(true);
        }).finally(() => {
            setLoadingPrograms(false);
        });
    }, [id]);

    const getEnrollDate = (program: Program) => {
        setEnrollDate(format(new Date(), 'yyyy-MM-dd'));
        setEnrollProgram(program);
    }

    const triggerEnroll = () => {
        if (enrollProgram) {
            handleEnroll(enrollProgram);
            setEnrollProgram(undefined);
        }
    };

    const cancelEnrollDate = () => {
        setEnrollProgram(undefined);
        setEnrollDate(format(new Date(), 'yyyy-MM-dd'));
    }

    const getUnenrollReason = (program: Program) => {
        setUnenrollReason('');
        setUnenrollProgram(program);
    }

    const triggerUnenroll = () => {
        if (unenrollProgram) {
            handleUnenroll(unenrollProgram);
            setUnenrollProgram(undefined);
        }
    };

    const cancelUnenrollReason = () => {
        setUnenrollProgram(undefined);
        setUnenrollReason('');
    }

    const handleUnenroll = (program: Program) => {
        setUnenrolling(true);
        setError('');
        setAvailablePrograms(p => [
            ...p,
            program
        ]);
        setEntrolledPrograms(ps => [
            ...ps.filter(p => p.id !== program.id)
        ]);

        unenrollInProgram(program.id, id, unenrollReason).then(pr => {
            setAvailablePrograms(ap => {
                const existingProgram = ap.find(p => p.id === pr.id);
                if (existingProgram) {
                    existingProgram.start = pr.start;
                    existingProgram.updated = pr.updated;
                    existingProgram.updatedBy = pr.updatedBy;
                    existingProgram.stop = pr.stop;
                    existingProgram.stopReason = pr.stopReason;
                }

                return [
                    ...ap
                ];
            });

            setUnenrollReason('');
        }).catch(e => {
            console.error(e);
            setError('Failed to unenroll');

            setAvailablePrograms(p => [
                ...p.filter(p => p.id !== program.id)
            ]);
            setEntrolledPrograms(ps => [
                ...ps,
                program
            ]);
        }).finally(() => {
            setUnenrolling(false);
        });
    };

    const handleEnroll = (program: Program) => {
        setEnrolling(true);
        setError('');
        setEntrolledPrograms(p => [
            ...p,
            program
        ]);
        setAvailablePrograms(ps => [
            ...ps.filter(p => p.id !== program.id)
        ]);

        enrollInProgram(program.id, id, enrollDate).then(pr => {
            setEntrolledPrograms(ep => {

                const existingProgram = ep.find(p => p.id === pr.id);
                if (existingProgram) {
                    existingProgram.start = pr.start;
                    existingProgram.updated = pr.updated;
                    existingProgram.updatedBy = pr.updatedBy;
                    existingProgram.stop = undefined;
                    existingProgram.stopReason = undefined;
                }

                return [
                    ...ep
                ];
            });

            setEnrollDate(format(new Date(), 'yyyy-MM-dd'));
        }).catch(e => {
            console.error(e);
            setError('Failed to enroll');

            setEntrolledPrograms(p => [
                ...p.filter(p => p.id !== program.id)
            ]);
            setAvailablePrograms(ps => [
                ...ps,
                program
            ]);
        }).finally(() => {
            setEnrolling(false);
        });
    };

    const getMainContent = () => {
        if (loadingPrograms) {
            return <Loader />;
        }

        if (failedToLoad) {
            return <>Failed to load</>
        }

        return (<>
            <ErrorMessages messages={[error]} />
            <div className="row align-items-center">
                <div className="column-auto">
                    <h3>Active Programs</h3>
                </div>
                <div className="column-auto">
                    {(enrolling || unenrolling) && <Loader />}
                </div>
            </div>
            {(!enrolledPrograms || enrolledPrograms.length === 0) && <div>No active programs</div>}
            {enrolledPrograms && enrolledPrograms.length > 0 && (
                <div className="list-item-container">
                    {enrolledPrograms.map(ep => (
                        <div key={ep.id} className="list-item">
                            <div className="row justify-content-between align-items-center">
                                <div className="column-medium-6">
                                    <div className="row">
                                        <div className="column">
                                            {ep.name}
                                        </div>
                                        <div className="column">
                                            {ep.start && <small>Enrolled - {format(parseJSONDateWithoutTime(ep.start), 'MM/dd/yyyy')}</small>}
                                        </div>
                                    </div>
                                </div>
                                <div className="column-medium-auto">
                                    <Protect user={me.user} roles={['Admin', 'Contributor']} behavior="hide"><Button id={`unenroll-${ep.id}`} text="Unenroll"
                                        className="secondary small" onClick={() => getUnenrollReason(ep)} /></Protect>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <h3>Enroll in Programs</h3>
            {(!availablePrograms || availablePrograms.length === 0) && <div>No available programs</div>}
            {availablePrograms && availablePrograms.length > 0 && (
                <div className="list-item-container">
                    {availablePrograms.map(ap => (
                        <div key={ap.id} className="list-item">
                            <div className="row justify-content-between align-items-center">
                                <div className="column-medium-6">
                                    {ap.name}
                                </div>
                                <div className="column-medium-auto">
                                    <Protect user={me.user} roles={['Admin', 'Contributor']} behavior="hide"><Button id={`enroll-${ap.id}`} text="Enroll"
                                        className="outline small" onClick={() => getEnrollDate(ap)} /></Protect>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </>);
    };

    return (
        <section id="main-content" tabIndex={-1}>
            {getMainContent()}
            <Modal id="unenroll-program-modal" visible={unenrollProgram !== undefined} className="small" onClose={cancelUnenrollReason} title="Provide a Reason">
                {unenrollProgram && (
                    <>
                        <div className="row">
                            <div className="column">
                                <label>Reason (optional):</label>
                                <Input id="unenroll-reason"
                                    type="multi"
                                    value={unenrollReason}
                                    onChange={(e) => setUnenrollReason(e)} />
                            </div>
                        </div>
                        <div className="row margin-top-5">
                            <div className="column">
                                <div className="row justify-content-between">
                                    <div className="column-medium-auto">
                                        <Button id="cancel-activate" text="Cancel" className="secondary" onClick={cancelUnenrollReason} />
                                    </div>
                                    <div className="column-medium-auto">
                                        <Button id="confirm-activate" text="Submit" onClick={triggerUnenroll} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </Modal>
            <Modal id="enroll-program-modal" visible={enrollProgram !== undefined} className="small" onClose={cancelEnrollDate} title="Select an Enrollment Date">
                {enrollProgram && (
                    <>
                        <div className="row">
                            <div className="column">
                                <label>Enrollment Date:</label>
                                <Input id="enroll-date"
                                    type="date"
                                    value={enrollDate}
                                    onChange={(e) => setEnrollDate(e)} />
                            </div>
                        </div>
                        <div className="row margin-top-5">
                            <div className="column">
                                <div className="row justify-content-between">
                                    <div className="column-medium-auto">
                                        <Button id="cancel-activate" text="Cancel" className="secondary" onClick={cancelEnrollDate} />
                                    </div>
                                    <div className="column-medium-auto">
                                        <Button id="confirm-activate" text="Submit" onClick={triggerEnroll} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </Modal>
        </section>);
};

export default ParticipantPrograms; 