import { format } from 'date-fns';
import { useState, useEffect } from 'react';
import Button from '../components/UI/Button';
import ErrorMessages from '../components/UI/ErrorMessages';
import Input from '../components/UI/Input';
import Loader from '../components/UI/Loader';
import Select from "../components/UI/Select";
import SideMenu from "../components/UI/SideMenu";
import WarningMessages from '../components/UI/WarnginMessages';
import { DELIVERY_ID } from '../constants/formTypes';
import { DELIVERY_FORMS_SUMMARY } from '../constants/reportTypes';
import Form from '../models/serviceModels/Form';
import { getForms } from '../services/formsService';
import { getReport, getReportData, runReport } from '../services/reportService';
import { getExportOptions, getReportsOptions } from "../utils/sideMenuHelpers";
import { ReportResult } from '../models/serviceModels/ReportResult';
import { convertToReportResults } from '../utils/reportHelpers';
import { FAILED_ID, SUCCESS_ID } from '../constants/reportStatuses';
import ApiErrorMessages from '../components/UI/ApiErrorMessages';
import OutputFormsReportResult from '../models/serviceModels/OutputFormsReportResult';
import useQuotas from '../hooks/useQuotas';

const defaultDateFrom = format(new Date(new Date().getFullYear(), 0, 1), 'yyyy-MM-dd');
const defaultDateTo = format(new Date(), 'yyyy-MM-dd');

const ReportsOutput = () => {

    const [activityDateFrom, setActivityDateFrom] = useState(defaultDateFrom);
    const [activityDateTo, setActivityDateTo] = useState(defaultDateTo);
    const [loadingForms, setLoadingForms] = useState(false);
    const [forms, setForms] = useState<Form[]>();
    const [selectedForm, setSelectedForm] = useState<string>();
    const [error, setError] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [apiError, setApiError] = useState<any>();
    const [reportResults, setReportResults] = useState<OutputFormsReportResult>();
    const quotas = useQuotas();

    useEffect(() => {
        document.title = "Reports and Exports"
    }, []);

    useEffect(() => {
        setLoadingForms(true);
        getForms(false).then(f => {
            setForms([
                ...f.filter(t => t.formTypeId === DELIVERY_ID && !t.draft)
            ]);
        }).catch(e => {
            console.error(e);
            setError('Failed to load forms');
        }).finally(() => {
            setLoadingForms(false);
        });
    }, []);

    const handleReset = () => {
        setSelectedForm('');
        setActivityDateFrom(defaultDateFrom);
        setActivityDateTo(defaultDateTo);
        setReportResults(undefined);
    };

    const getParameters = (): { [key: string]: string | null } => {
        return {
            from: activityDateFrom,
            to: activityDateTo,
            formId: selectedForm || null
        };
    }

    const handleSubmit = () => {
        setSubmitting(true);
        setError('');
        setApiError(undefined);

        runReport({
            type: DELIVERY_FORMS_SUMMARY,
            parameters: getParameters()
        }).then(result => {
            pollForResult(result).finally(() => {
                setSubmitting(false);
            });
        }).catch(e => {
            console.error(e);
            setError('Failed to run report');
            setSubmitting(false);
        })
    };

    const pollForResult = (result: ReportResult): Promise<void> => {
        return new Promise((resolve, reject) => {
            const callGetReport = () => {
                getReport(result.id).then(r => {
                    if (r.statusId === FAILED_ID) {
                        setError('Report failed to run.  Please try again later');
                        reject();
                    } else if (r.statusId === SUCCESS_ID) {
                        getReportData(result.id).then(d => {
                            convertToReportResults<OutputFormsReportResult>(d).then(dd => {
                                setReportResults(dd);
                                resolve();
                            }).catch(e => {
                                console.error(e);
                                setApiError(e);
                            });
                            resolve();
                        }).catch(e => {
                            console.error(e);
                            setApiError(e);
                            reject();
                        })
                    } else {
                        setTimeout(callGetReport, 3000);
                    }
                }).catch(e => {
                    console.error(e);
                    setApiError(e);
                    reject();
                });
            };

            callGetReport();
        });
    };

    const getButtons = () => {
        return (<div className="row margin-top-4 justify-content-between">
            <div className="column-medium-auto">
                <Button id="reset-button" className="secondary" text="Reset" onClick={handleReset} disabled={submitting} />
            </div>
            <div className="column-medium-auto">
                <Button id="submit-button" text="Submit" onClick={handleSubmit} disabled={submitting} loading={submitting} />
            </div>
        </div>)
    }

    const getSelectedFormName = () => {
        if (!forms || forms.length === 0 || !selectedForm) {
            return null;
        }
        const form = forms.find(f => f.id === selectedForm);
        return form?.name;
    };

    const getCriteria = () => {
        if (loadingForms || quotas.loading) {
            return <Loader />;
        }

        return (
            <>
                {quotas.limits?.atOrBeyondReportLimit && <ErrorMessages messages={['Your organization has reached the daily reporting limit']} />}
                <ErrorMessages messages={[error]} />
                <ApiErrorMessages error={apiError} />
                {!loadingForms && forms && (
                    <>
                        {forms.length === 0 && <WarningMessages messages={['No Outputs and Outcome forms found']} />}
                        <div className="row margin-top-4">
                            <div className="column-medium-3">
                                <Select id="select-form" disabled={forms.length === 0} label="Form" value={selectedForm} onChange={(e) => setSelectedForm(e)}>
                                    <option>--Select One--</option>
                                    {forms.map(f => {
                                        return <option key={f.id} value={f.id}>{f.name}</option>
                                    })}
                                </Select>
                            </div>
                        </div>
                        {selectedForm && <div className="row margin-top-4">
                            <div className="column-medium-3">
                                <Input id="active-date-from" label="Activity Date From"
                                    value={activityDateFrom} onChange={(e) => setActivityDateFrom(e)}
                                    type="date" />
                            </div>
                            <div className="column-medium-3">
                                <Input id="active-date-to" label="Activity Date To"
                                    value={activityDateTo} onChange={(e) => setActivityDateTo(e)}
                                    type="date" />
                            </div>
                        </div>}
                    </>
                )}
                {selectedForm && getButtons()}
            </>
        );
    };

    const getReportResults = () => {
        if (!reportResults) {
            return <></>;
        }

        const reportTables = [];
        
        reportTables.push(<div key="total-submissions" className="column-12 margin-top-5">
            <h3>Total Submissions</h3>
            <div className="text-large">{reportResults.total}</div>
        </div>)

        if (reportResults.numberFields && reportResults.numberFields.length > 0) {
            reportTables.push(<div key="number-fields" className="column-medium-6 margin-top-5">
                <h3>{getSelectedFormName()} - Numeric Field Summary</h3>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">field</th>
                            <th scope="col">average</th>
                            <th scope="col">median</th>
                            <th scope="col">total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reportResults.numberFields.sort((a, b) => {
                            const firstValue = a.fieldLabel.toLowerCase();
                            const secondValue = b.fieldLabel.toLowerCase();
                            if (firstValue < secondValue) {
                                return -1;
                            }

                            if (firstValue > secondValue) {
                                return 1;
                            }

                            return 0;
                        }).map((a, i) => {
                            return (<tr key={i}>
                                <td>{a.fieldLabel}</td>
                                <td>{Number(a.average).toFixed(2)}</td>
                                <td>{Number(a.median).toFixed(2)}</td>
                                <td>{a.total}</td>
                            </tr>)
                        })}
                    </tbody>
                </table>
            </div>);
        } else {
            reportTables.push(<div key="number-fields" className="column-medium-6 margin-top-5">
                <h3>{getSelectedFormName()} - Numeric Field Summary</h3>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">field</th>
                            <th scope="col">average</th>
                            <th scope="col">median</th>
                            <th scope="col">total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={4}>
                                <div className="row justify-content-center">
                                    <div className="column-auto">
                                        No results found
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>);
        }

        if (reportResults.singleValueFields && reportResults.singleValueFields.length > 0) {
            reportTables.push(<div key="single-value-fields" className="column-medium-6 margin-top-5">
                <h3>{getSelectedFormName()} - Single Value Field Summary</h3>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">field</th>
                            <th scope="col">value</th>
                            <th scope="col">count</th>
                            <th scope="col">percentage</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reportResults.singleValueFields.sort((a, b) => {
                            const firstValue = a.fieldLabel.toLowerCase();
                            const secondValue = b.fieldLabel.toLowerCase();
                            if (firstValue < secondValue) {
                                return -1;
                            }

                            if (firstValue > secondValue) {
                                return 1;
                            }

                            return 0;
                        }).map((a, i) => {
                            return (<tr key={i}>
                                <td>{a.fieldLabel}</td>
                                <td>{a.fieldValue}</td>
                                <td>{a.counts}</td>
                                <td>{Number(a.percentage).toFixed(1)}%</td>
                            </tr>)
                        })}
                    </tbody>
                </table>
            </div>);
        } else {
            reportTables.push(<div key="single-value-fields" className="column-medium-6 margin-top-5">
                <h3>{getSelectedFormName()} - Single Value Field Summary</h3>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">field</th>
                            <th scope="col">value</th>
                            <th scope="col">count</th>
                            <th scope="col">percentage</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={4}>
                                <div className="row justify-content-center">
                                    <div className="column-auto">
                                        No results found
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>);
        }

        if (reportResults.multiValueFields && reportResults.multiValueFields.length > 0) {
            reportTables.push(<div key="multi-value-fields" className="column-medium-6 margin-top-5">
                <h3>{getSelectedFormName()} - Multi Value Field Summary</h3>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">field</th>
                            <th scope="col">value</th>
                            <th scope="col">count</th>
                            <th scope="col">percentage</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reportResults.multiValueFields.sort((a, b) => {
                            const firstValue = a.fieldLabel.toLowerCase();
                            const secondValue = b.fieldLabel.toLowerCase();
                            if (firstValue < secondValue) {
                                return -1;
                            }

                            if (firstValue > secondValue) {
                                return 1;
                            }

                            return 0;
                        }).map((a, i) => {
                            return (<tr key={i}>
                                <td>{a.fieldLabel}</td>
                                <td>{a.fieldValue}</td>
                                <td>{a.counts}</td>
                                <td>{Number(a.percentage).toFixed(1)}%</td>
                            </tr>)
                        })}
                    </tbody>
                </table>
            </div>);
        } else {
            reportTables.push(<div key="multi-value-fields" className="column-medium-6 margin-top-5">
                <h3>{getSelectedFormName()} - Multi Value Field Summary</h3>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">field</th>
                            <th scope="col">value</th>
                            <th scope="col">count</th>
                            <th scope="col">percentage</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={4}>
                                <div className="row justify-content-center">
                                    <div className="column-auto">
                                        No results found
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>);
        }

        return (<div className="row">
            {reportTables}
        </div>);
    };

    const getMainContent = () => {
        return (<>
            {getCriteria()}
            {getReportResults()}
        </>);
    };

    const getHeaderContent = () => {
        return <h1>Run Outputs and Outcomes Reports</h1>
    };

    return (
        <section id="main-content" tabIndex={-1}>
            <div className="section">
                <SideMenu ariaLabel="Reports Side Menu"
                    id="reports-menu"
                    title="Reports"
                    secondaryTitle="Exports"
                    secondaryOptions={getExportOptions()}
                    options={getReportsOptions()}
                    headerContent={getHeaderContent()}
                    mainContent={getMainContent()}
                />
            </div>

        </section>
    );
};

export default ReportsOutput;
