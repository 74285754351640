import { DEMOGRAPHICS, OUTPUTS_AND_OUTCOMES } from "../../../constants/reportTypes";
import DemographicsSummary from "./DemographicsSummary";
import OutputsAndOutcomesSummary from "./OutputsAndOutcomesSummary";
import SummaryProps from "./SummaryProps";

const mapping: { [key: string]: (props: SummaryProps) => JSX.Element } = {
    [DEMOGRAPHICS]: DemographicsSummary,
    [OUTPUTS_AND_OUTCOMES]: OutputsAndOutcomesSummary
};

export {
    mapping
};