import React, { useContext, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";  //NavLink makes it easy to highlight the active link/page on the navbar
import { useAuth0 } from "@auth0/auth0-react";
import "./MainNav.css";
import Button from "../UI/Button";
import useNavigationEnabled from "../../hooks/useNavigationEnabled";
import Protect from "./Protect";
import { MeContext } from "../../App";
import { manageFormsEnabled, reportsV2Enabled } from "../../utils/features";

const MainNavigation = () => {
  const { logout, isAuthenticated, isLoading } = useAuth0();
  const [isNavExpanded, setIsNavExpanded] = useState<boolean>(false);
  const navigationEnabled = useNavigationEnabled();
  const me = useContext(MeContext);
  
  const onlyEnableManage = useMemo(() => {
    if (me.loading || !me.user) {
      return true;
    }

    return me.user.organizationAccessDisabled || me.user.organizationPlanCancelled || me.user.organizationTrialEnded;
  }, [me]);

  const logoutHandler = () => {
    logout({ returnTo: window.location.origin });
  };

  const mobileNavToggleHandler = () => {
    setIsNavExpanded(!isNavExpanded);
  }

  const mobileNavCloseHandler = () => {
    setIsNavExpanded(false);
  }

  const navbarItems =
    (<ul>
      {navigationEnabled && (<>
        {!onlyEnableManage &&
          (<>
            <li>
              <NavLink onClick={mobileNavCloseHandler} className={(navData) => (navData.isActive ? "active" : "")} to="/home" title="Search for participants">Participants</NavLink>
            </li>
            <Protect roles={['Admin', 'Contributor']} behavior="hide" user={me.user}>
              <li>
                <NavLink onClick={mobileNavCloseHandler} className={(navData) => (navData.isActive ? "active" : "")} to="/tracking/delivery" title="Track delivery, assesment, and plan data">Track Data</NavLink>
              </li>
            </Protect>
            <li>
              {!reportsV2Enabled && <NavLink onClick={mobileNavCloseHandler} className={(navData) => (navData.isActive ? "active" : "")} to="/reports/demographics" title="Create reports or export data from services delivered">Report</NavLink>}
              {reportsV2Enabled && <NavLink onClick={mobileNavCloseHandler} className={(navData) => (navData.isActive ? "active" : "")} to="/reports" title="Create reports or export data from services delivered">Report</NavLink>}
            </li>
            {!manageFormsEnabled && <Protect roles={['Admin']} behavior="hide" user={me.user}>
              <li>
                <NavLink onClick={mobileNavCloseHandler} className={(navData) => (navData.isActive ? "active" : "")} to="/forms/all" title="Edit forms or build new ones">Build Forms</NavLink>
              </li>
            </Protect>}
          </>)}
        <Protect roles={['Admin']} behavior="hide" user={me.user}>
          <li>
            <NavLink onClick={mobileNavCloseHandler} className={(navData) => (navData.isActive ? "active" : "")} to="/admin" title="Manage users, view bills, reset passwords, etc">Manage Site</NavLink>
          </li>
        </Protect>
      </>)}
      {isAuthenticated && <li>
        <Button className="inverse" id="logout-button" onClick={logoutHandler} text="Logout" />
      </li>}

      {
        navigationEnabled && (
          <>
            {!onlyEnableManage && <li className="align-self-center">
              <Button className="inverse" href="/user/profile" id="profile-button" text="My Profile" />
            </li>}
            <li className="align-self-center">
              <Button className="inverse" href="/help" id="help-button" text="Help" />
            </li>
          </>
        )
      }
    </ul>);

  if (isLoading || me.loading) {
    return (
      <header className="header">
        <nav className="navigation" aria-label="Main" >
        </nav>
      </header>);
  }

  return (
    <React.Fragment>

      <header className="header">
        <nav className="navigation">

          {isAuthenticated && <NavLink to="/" title="Home/Search for Participants" className="margin-start-5">
            <div className="logo">countbubble</div>
          </NavLink>}

          {!isAuthenticated && <li>
            <a href="https://countbubble.com" className="logo" title="learn more about countbubble">countbubble</a>
          </li>}
          <button className="hamburger-btn" onClick={mobileNavToggleHandler}>
            <span className="hamburger-btn-line"></span>
            <span className="hamburger-btn-line"></span>
            <span className="hamburger-btn-line"></span>
          </button>

          <div className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"}>
            {navbarItems}
          </div>
        </nav>
      </header>
    </React.Fragment >
  );
};

export default MainNavigation;