import { ForwardedRef, forwardRef } from "react";
import ErrorMessages, { ErrorMessagesProps } from "./ErrorMessages";
import Select, { SelectProps } from "./Select";

interface ValidatedSelectProps extends SelectProps, ErrorMessagesProps {
}

const ValidatedSelect = forwardRef((props: ValidatedSelectProps, ref?: ForwardedRef<any>) => {
    return (<>
        <Select {...props} ref={ref} />
        <ErrorMessages {...props} />
    </>
    );
});

export default ValidatedSelect;
