import axios from "axios";
import AssignFormToProgramRequest from "../models/serviceModels/AssignFormToProgramRequest";
import CreateProgramRequest from "../models/serviceModels/CreateProgramRequest";
import Form from "../models/serviceModels/Form";
import PagedData from "../models/serviceModels/PagedData";
import Program from "../models/serviceModels/Program";
import { SearchProgramsRequest } from "../models/serviceModels/SearchProgramsRequest";
import UnassignFormFromProgramRequest from "../models/serviceModels/UnassignFormFromProgramRequest";
import UpdateProgramRequest from "../models/serviceModels/UpdateProgramRequest";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const searchPrograms =  async (request?: SearchProgramsRequest): Promise<PagedData<Program>> => {
    const parameters = [
        `query=${request?.query || ''}`,
        `skip=${request?.skip || ''}`,
        `take=${request?.take || ''}`,
        `includeDeactivated=${request?.includeDeactivated ? 'true' : 'false'}`
    ];
    const result = await axios.get(`${baseUrl}/programs?${parameters.join('&')}`);
    return result.data;
};

const createProgram = async (request: CreateProgramRequest): Promise<Program> => {
    const result = await axios.post(`${baseUrl}/programs`, {
        ...request
    });
    return result.data;
};

const getProgramById = async (id: string): Promise<Program> => {
    const result = await axios.get(`${baseUrl}/programs/${id}`);
    return result.data;
};

const updateProgram = async (request: UpdateProgramRequest): Promise<Program> => {
    const result = await axios.put(`${baseUrl}/programs/${request.id}`, { ...request });
    return result.data;
}

const activateProgram = async (programId: string): Promise<void> => {
    await axios.post(`${baseUrl}/programs/${programId}/activate`);  
};

const deactivateProgram = async (programId: string): Promise<void> => {
    await axios.post(`${baseUrl}/programs/${programId}/deactivate`);  
};

const assignFormToProgram = async (request: AssignFormToProgramRequest): Promise<void> => {
    await axios.post(`${baseUrl}/programs/${request.programId}/forms/${request.formId}/assign`);
}

const unassignFormFromProgram = async (request: UnassignFormFromProgramRequest): Promise<void> => {
    await axios.post(`${baseUrl}/programs/${request.programId}/forms/${request.formId}/unassign`);
}

const getFormsByProgramId = async (id: string): Promise<Form[]> => {
    const result = await axios.get(`${baseUrl}/programs/${id}/forms`);
    return result.data;
}

const getEnrolledPrograms = async (clientId: string): Promise<Program[]> => {
    const result = await axios.get(`${baseUrl}/programs/enrolled?clientId=${clientId}`);
    return result.data;
}

const enrollInProgram = async (programId: string, clientId: string, enrollDate: string): Promise<Program> => {
    const result = await axios.post(`${baseUrl}/programs/${programId}/enroll`, {
        clientId,
        enrollDate
    });
    return result.data;
}

const unenrollInProgram = async (programId: string, clientId: string, reason?: string): Promise<Program> => {
    const result = await axios.post(`${baseUrl}/programs/${programId}/unenroll`, {
        clientId,
        stopReason: reason || null
    });
    return result.data;
}

const getAvailablePrograms = async (clientId: string): Promise<Program[]> => {
    const result = await axios.get(`${baseUrl}/programs/available?clientId=${clientId}`);
    return result.data;
};

export {
    searchPrograms,
    createProgram,
    getProgramById,
    updateProgram,
    assignFormToProgram,
    unassignFormFromProgram,
    getFormsByProgramId,
    getAvailablePrograms,
    unenrollInProgram,
    enrollInProgram,
    getEnrolledPrograms,
    activateProgram,
    deactivateProgram
}